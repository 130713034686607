//$app_mode
//1 - через Telegram
//2 - через браузер
const state = {
    $app_mode: 2,
    cart: [],
    wishlist: [],
    isLoading: true,
    userId: '',
    auth_key: '',
    isShowTGMainBtn: false,
    razdelsList: [],
    currentRazdelId: 0,
    defaultRazdelId: 7,
    isPopupOpen: false,
    choosen_filters: {},
    appInfo: {}
};

export default state